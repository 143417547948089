import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import Container from '@mui/material/Container'
import useStore from '@/hooks/useStore'
import { getStrapiImage, getStrapiImagePlaceholder } from '@/lib/api'

const Value = () => {
  const data = useStore((state) => state.home?.value) as Value
  return (
    <Container>
      <Stack sx={{ py: 6 }} spacing={4}>
        <Stack
          justifyContent="center"
          sx={{ width: { xs: 1, md: 0.5 }, mx: 'auto' }}
        >
          <Typography
            textAlign="center"
            fontWeight={700}
            variant="h4"
            gutterBottom
          >
            {data?.title}
          </Typography>
          <Typography textAlign="center" color="text.secondary" gutterBottom>
            {data?.description}
          </Typography>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          {data?.items?.map((el) => (
            <Stack
              key={el?.id}
              spacing={2}
              sx={{
                p: 3,
                bgcolor: 'action.hover',
                transition: '500ms',
                '&:hover': {
                  boxShadow: '0 5px 10px 0 rgba(0,0,0,0.1)',
                },
              }}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack sx={{ width: 20, height: 20, position: 'relative' }}>
                  <Image
                    src={getStrapiImage(el?.media)}
                    blurDataURL={getStrapiImagePlaceholder(el?.media)}
                    placeholder="blur"
                    alt="Icon"
                    layout="fill"
                    objectFit="contain"
                    objectPosition={0}
                  ></Image>
                </Stack>
                <Typography variant="h5" fontWeight={700} gutterBottom>
                  {el?.title}
                </Typography>
              </Stack>
              <Typography color="text.secondary" gutterBottom>
                {el?.description}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Container>
  )
}

export default Value
